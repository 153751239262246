
import React, { Suspense, useEffect } from "react";
import { useLocation } from "react-router";

declare global {
    interface Window {
        dataLayer: Record<string, any>[];
    }
}


export default function GTMComponent() {
    const location = useLocation();

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        event: "pageview",
        page: {
            url: window.location.href,
            path: location.pathname,
            title: document.title,
        },
        });
    }, [location]);

    return (<></>);
}