import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import type { ApplicationPayload } from "ee/constants/ReduxActionConstants";
import { useDispatch, useSelector } from "react-redux";
import type { AppState } from "ee/reducers";
import {
  getCurrentBasePageId,
  getViewModePageList,
} from "selectors/editorSelectors";
import { getCurrentWorkspaceId } from "ee/selectors/selectedWorkspaceSelectors";
import { getCurrentUser } from "selectors/usersSelectors";
import type { User } from "constants/userConstants";
import type { Theme } from "constants/DefaultTheme";
import { getThemeDetails, ThemeMode } from "selectors/themeSelectors";
import HtmlTitle from "../AppViewerHtmlTitle";
import { NAVIGATION_SETTINGS } from "constants/AppConstants";
import PageMenu from "pages/AppViewer/PageMenu";
import { useHref } from "pages/Editor/utils";
import { builderURL } from "ee/RouteBuilder";
import TopHeader from "./components/TopHeader";
import Sidebar from "./Sidebar";
import { getCurrentApplication } from "ee/selectors/applicationSelectors";
import { useIsMobileDevice } from "utils/hooks/useDeviceDetect";
import { setAppViewHeaderHeight } from "actions/appViewActions";
import AnalyticsUtil from "ee/utils/AnalyticsUtil";

//@@
import Button from "pages/AppViewer/AppViewerButton";
import styled from "styled-components";
import {
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import { getSelectedAppTheme } from "selectors/appThemingSelectors";

const CustomBottom = styled.div`
  position: fixed;
  bottom: 0;
  padding: 15px 30px;
  width: 100%;
  z-index: 1000;
  color: #fff;
  background-color: #211f54;
`;

const CustomHeader = styled.div`
  position: fixed;
  to: 0;
  padding: 15px 45px;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0px 3.97327px 19.8663px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  button {
    margin: 0 10px;
  }
  .rightCustomMenu {
    display: flex;
  }
  .rightBorder {
    border-right: 1px solid #c8c8c8;
  }
`;

//@@


export function Navigation() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const isEmbed = queryParams.get("embed") === "true";
  const showNavBar = queryParams.get("navbar") === "true";
  const hideHeader = isEmbed && !showNavBar;
  const [isMenuOpen, setMenuOpen] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const basePageId = useSelector(getCurrentBasePageId);
  const editorURL = useHref(builderURL, { basePageId });
  const currentWorkspaceId: string = useSelector(getCurrentWorkspaceId);
  const currentUser: User | undefined = useSelector(getCurrentUser);
  const lightTheme: Theme = useSelector((state: AppState) =>
    getThemeDetails(state, ThemeMode.LIGHT),
  );
  const currentApplicationDetails: ApplicationPayload | undefined = useSelector(
    getCurrentApplication,
  );
  const pages = useSelector(getViewModePageList);
  const isMobile = useIsMobileDevice();
  const dispatch = useDispatch();

  useEffect(() => {
    const header = document.querySelector(".js-appviewer-header");

    dispatch(setAppViewHeaderHeight(header?.clientHeight || 0));

    return () => {
      dispatch(setAppViewHeaderHeight(0));
    };
  }, [
    currentApplicationDetails?.applicationDetail?.navigationSetting?.navStyle,
    currentApplicationDetails?.applicationDetail?.navigationSetting
      ?.orientation,
  ]);
  useEffect(() => {
    if (showNavBar && currentApplicationDetails) {
      AnalyticsUtil.logEvent("APP_VIEWED_WITH_NAVBAR", {
        id: currentApplicationDetails.id,
      });
    }
  }, [showNavBar, currentApplicationDetails]);

  const renderNavigation = () => {
    if (
      currentApplicationDetails?.applicationDetail?.navigationSetting
        ?.orientation === NAVIGATION_SETTINGS.ORIENTATION.SIDE
    ) {
      return (
        <>
          {/*
           * We need to add top header since we want the current mobile
           * navigation experience until we create the new sidebar for mobile.
           */}
          {isMobile ? (
            <TopHeader
              currentApplicationDetails={currentApplicationDetails}
              currentUser={currentUser}
              currentWorkspaceId={currentWorkspaceId}
              isMenuOpen={isMenuOpen}
              pages={pages}
              setMenuOpen={setMenuOpen}
              showUserSettings={!isEmbed}
            />
          ) : (
            <Sidebar
              currentApplicationDetails={currentApplicationDetails}
              currentUser={currentUser}
              currentWorkspaceId={currentWorkspaceId}
              pages={pages}
              showUserSettings={!isEmbed}
            />
          )}
        </>
      );
    }

    return (
      <TopHeader
        currentApplicationDetails={currentApplicationDetails}
        currentUser={currentUser}
        currentWorkspaceId={currentWorkspaceId}
        isMenuOpen={isMenuOpen}
        pages={pages}
        setMenuOpen={setMenuOpen}
        showUserSettings={!isEmbed}
      />
    );
  };
  


  //@@
  const selectedTheme = useSelector(getSelectedAppTheme);

  const renderNavigationFutura = () => {
    const search = window?.location?.search;
    
    const domains:any = {
      'predev': {
        platform: 'FUTURA-PREDEV',
        domainUrl: 'https://predev-portal.futuralabs.rocks'
      },
      'rocks': {
        platform: 'FUTURA-DEV',
        domainUrl: 'https://portal.futuralabs.rocks'
      }, 'uat': {
        platform: 'FUTURA-UAT',
        domainUrl: 'https://uat-portal.futura.healthcare'
      }, 'prod': {
        platform: 'FUTURA',
        domainUrl: 'https://portal.futura.healthcare'
      }
    };
    const selected = Object.keys(domains).find(key=> {
      return window?.location?.host?.includes(key);
    }) || 'prod';
    const platform = domains[selected].platform;
    const domainUrl = domains[selected].domainUrl;
    


    return <>
            <CustomBottom>
              <span>{platform} © {new Date().getFullYear()}</span>
            </CustomBottom>
            <CustomHeader>
              <div>
                <a href={domainUrl+'/inicio'+search}>
                  <img src="https://uploads-ssl.webflow.com/632b689f3a48f1d8bbd99d86/6335eebdd547037d8cd544cb_futura-logo.svg" />
                </a>
              </div>
              <div className="rightCustomMenu">
                <div className="rightBorder">
                  <Button
                  
                   borderRadius={
                     selectedTheme.properties.borderRadius.appBorderRadius
                   }
                    boxShadow="none"
                    buttonColor={selectedTheme.properties.colors.primaryColor}
                    buttonVariant="TERTIARY"
                    className="h-8"
                    data-cy="viewmode-share"
                    onClick={() => {
                      window.location.replace( domainUrl + "/inicio"+search);
                    }}
                    text="Inicio"
                    display="inline"
                  />
                  <Button
                    borderRadius={
                      selectedTheme.properties.borderRadius.appBorderRadius
                    }
                    boxShadow="none"
                    buttonColor={selectedTheme.properties.colors.primaryColor}
                    buttonVariant="TERTIARY"
                    className="h-8"
                    data-cy="viewmode-share"
                    onClick={() => {
                      window.location.replace( domainUrl + "/pacientes"+search);
                    }}
                    text="Pacientes"
                    display="inline"
                  />
                  <Button
                    borderRadius={
                      selectedTheme.properties.borderRadius.appBorderRadius
                    }
                    boxShadow="none"
                    buttonColor={selectedTheme.properties.colors.primaryColor}
                    buttonVariant="TERTIARY"
                    className="h-8"
                    data-cy="viewmode-share"
                    onClick={() => {
                      window.location.replace( domainUrl + "/ordenes"+search);
                    }}
                    text="Órdenes Clínicas"
                    display="inline"
                  />
                </div>
                <div>
                  <Button
                    borderRadius={
                      selectedTheme.properties.borderRadius.appBorderRadius
                    }
                    boxShadow="none"
                    buttonColor={selectedTheme.properties.colors.primaryColor}
                    buttonVariant="TERTIARY"
                    className="h-8"
                    data-cy="viewmode-share"
                    onClick={() => {
                      window.location.replace( domainUrl + "/mi-perfil"+search);
                    }}
                    text="Mi cuenta"
                  />
                </div>
              </div>
            </CustomHeader>
          </>;
  }


  const currentApplication = useSelector(getCurrentApplication);

  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  //const isEmbed = queryParams.get("embed");
  //const hideHeader = !!isEmbed;
  const isFutura = window?.location?.pathname?.includes("futura");
  const isLogin = window?.location?.pathname?.includes("sesion");
  

  if ((hideHeader && !isFutura) || isLogin) return <HtmlTitle />;
  //@@


  //@ if (hideHeader) return <HtmlTitle />;

  return (
    <ThemeProvider theme={lightTheme}>
      <div ref={headerRef}>
        {/* Since the Backend doesn't have navigationSetting field by default
        and we are creating the default values only when any nav settings via the
        settings pane has changed, we need to hide the navbar ONLY when the showNavbar
        setting is explicitly set to false by the user via the settings pane. */}
        {currentApplicationDetails?.applicationDetail?.navigationSetting
          ?.showNavbar !== false && !isFutura && renderNavigation()}
        
        {currentApplicationDetails?.applicationDetail?.navigationSetting
          ?.showNavbar !== false && isFutura && renderNavigationFutura()}

        {/* Mobile Sidebar */}
        <PageMenu
          application={currentApplicationDetails}
          headerRef={headerRef}
          isOpen={isMenuOpen}
          pages={pages}
          setMenuOpen={setMenuOpen}
          url={editorURL}
        />
      </div>
    </ThemeProvider>
  );
}

export default Navigation;
