import React, { useEffect } from "react";
import { connect } from "react-redux";
import { APPLICATIONS_URL } from "constants/routes";
import { Button } from "@appsmith/ads";
import { flushErrorsAndRedirect } from "actions/errorActions";
import {
  BACK_TO_HOMEPAGE,
  createMessage,
  PAGE_NOT_FOUND,
  PAGE_NOT_FOUND_TITLE,
} from "ee/constants/messages";
import AnalyticsUtil from "ee/utils/AnalyticsUtil";

import Page from "./Page";

interface Props {
  // TODO: Fix this the next time the file is edited
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  flushErrorsAndRedirect: any;
}

function PageNotFound(props: Props) {
  const { flushErrorsAndRedirect } = props;
  useEffect(() => {
    AnalyticsUtil.logEvent("PAGE_NOT_FOUND");
  }, []);


  const search = window?.location?.search;
    
  const domains:any = {
    'predev': {
      platform: 'FUTURA-PREDEV',
      domainUrl: 'https://predev-portal.futuralabs.rocks'
    },
    'rocks': {
      platform: 'FUTURA-DEV',
      domainUrl: 'https://portal.futuralabs.rocks'
    }, 'uat': {
      platform: 'FUTURA-UAT',
      domainUrl: 'https://uat-portal.futura.healthcare'
    }, 'prod': {
      platform: 'FUTURA',
      domainUrl: 'https://portal.futura.healthcare'
    }
  };
  const selected = Object.keys(domains).find(key=> {
    return window?.location?.host?.includes(key);
  }) || 'prod';
  const platform = domains[selected].platform;
  const domainUrl = domains[selected].domainUrl;

  return (
    <Page
      cta={
        <Button
          className="mt-4 button-position"
          endIcon="right-arrow"
          kind="primary"
          onClick={() => {
            // flushErrorsAndRedirect(APPLICATIONS_URL)
            window.location.replace( domainUrl + "/inicio"+search);
          }}
          size="md"
        >
          {createMessage(BACK_TO_HOMEPAGE)}
        </Button>
      }
      description="Página no encontrada, regresar a portal clínico"
      errorCode={createMessage(PAGE_NOT_FOUND_TITLE)}
      title={createMessage(PAGE_NOT_FOUND)}
    />
  );
}

export default connect(null, {
  flushErrorsAndRedirect,
})(PageNotFound);
